<template>
  <div class="table-classic-new">
    <table>
      <thead>
        <tr>
          <th
            v-if="checkboxes"
            class="checkbox"
          >
            <div>
              <ButtonCheckbox
                v-model="headerChecked"
                name="header-checkbox"
                :mix="headerMixed"
                @change="toggleAllCheckboxes()"
              />
            </div>
          </th>
          <th
            v-for="col in columns"
            :key="col.key"
            :class="col.key"
          >
            <component
              :is="col.notSortable ? 'div' : 'button'"
              class="button-filter"
              @click="!col.notSortable && sort(col)"
            >
              <UisSorting
                v-if="!col.notSortable"
                size="16"
              />
              {{ col.name }}
              <component
                :is="col.icon"
                v-if="col.icon"
                size="16"
              />
            </component>
          </th>
        </tr>
        <tr class="separator" />
      </thead>
      <tbody>
        <template v-for="row, index in rows">
          <tr
            :key="index"
            :class="[
              row._variant,
              `ligne-${row.id}`,
              {
                highlighted: row._checked,
                disabled: !row._routeName,
                expanded: row._expanded,
              },
            ]"
            @click="!row._disabled ? handleRowClick(row) : null"
          >
            <td
              v-if="checkboxes"
              class="checkbox"
            >
              <div>
                <ButtonCheckbox
                  v-model="row._checked"
                  class="visibility-hidden"
                  name="header-checkbox"
                  :mix="row._mixed"
                  @change="row._children?.length ? toggleChildrenCheckboxes(row) : null"
                  @click.native.stop
                />
              </div>
            </td>
            <td
              v-for="col in columns"
              :key="col.key"
              :class="col.key"
            >
              <!-- @slot Emplacement pour le contenu des cellules du tableau. -->
              <slot
                :name="col.key"
                :value="row"
              >
                <!-- On met une valeur par défaut au cas où on oublierait de surdéfinir le rendu -->
                {{ row[col.key] }}
              </slot>
            </td>
          </tr>
          <tr
            v-if="row._additional"
            :key="`additional_${index}`"
            class="additional-line"
          >
            <!-- Prend toute la largeur du tableau -->
            <td :colspan="checkboxes ? columns.length + 1 : columns.length">
              <!-- @slot Emplacement pour des éléments sous les lignes. -->
              <slot
                name="additional-line"
                :value="row"
              />
            </td>
          </tr>
          <tr
            v-if="row._children?.length && row._expanded"
            :key="`child_${index}`"
            class="child-row"
          >
            <td :colspan="checkboxes ? columns.length + 1 : columns.length">
              <!-- @slot Emplacement pour des éléments sous les lignes. -->
              <slot
                name="child-row"
                :value="row"
              />
            </td>
          </tr>
          <tr
            :key="`space_${index}`"
            class="separator"
          />
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ButtonCheckbox, Pagination } from "@lde/core_lde_vue";

import { getNestedProperty } from "@/modules/utils";

import { UisSorting } from "@iconscout/vue-unicons-solid";

/**
 * Affiche un "tableau" interactif (dropdown, bouton, input…) avec son contenu.
 * Il est possible de trier d'après les colonnes.
 * Le tableau peut contenir un tableau imbriqué, dont les slots devront commencer par "child-".
 */
export default {
  name: "TableClassic",
  components: {
    ButtonCheckbox,
    Pagination,
    UisSorting,
  },
  model: {
    prop: "items",
    event: "change",
  },
  props: {
    /**
     * En-têtes du tableau.
     */
    columns: {
      type: Array,
      default: () => [],
    },
    /**
     * Lignes du tableau.
     */
    rows: {
      type: Array,
      required: true,
    },
    /**
     * Permet d'afficher des checkboxes en début de ligne.
     */
    checkboxes: {
      type: Boolean,
      default: false,
    },
    /**
     * Définit si le tri se fait via l'API ou en front.
     */
    internalSort: {
      type: Boolean,
      default: false,
    },
    /**
     * Clé et sens du tri des lignes du tableau pour l'API (non nécessaire si internalSort === true).
     */
    activeSort: {
      type: Object,
      default: undefined,
    },
  },
  emits: [
    /**
     * Déclenché au clic sur un des boutons de tri.
     * @param {Object} - Clé et sens du tri.
     */
    "sort",
  ],
  data() {
    return {
      headerChecked: false,
      headerMixed: false,
      internalActiveSort: {},
    };
  },
  watch: {
    rows: {
      handler() {
        this.rows.forEach((row) => {
          if (row._children?.length) {
            // Coche une ligne si au moins une sous-ligne est cochée
            this.$set(row, "_checked", row._children.some((child) => child._checked));

            // Variante "mix" pour une ligne si au moins une sous-ligne est cochée, mais pas toutes
            this.$set(row, "_mixed", row._children.filter((child) => child._checked).length !== row._children.length);
          }
        });

        // Coche la checkbox principale si au moins une ligne est cochée
        const headerCheckedFromRows = this.rows.some((row) => row._checked);

        // Coche la checkbox principale si au moins une sous-ligne est cochée
        const headerCheckedFromChildren = this.rows.some((row) => row._children?.some((child) => child._checked));

        // Variante "mix" pour la checkbox principale si au moins une ligne est cochée, mais pas toutes
        const headerMixedFromRows = this.rows.filter((row) => row._checked).length !== this.rows.length;

        // Variante "mix" pour la checkbox principale si au moins une ligne est mixed
        const headerMixedFromChildren = this.rows.some((row) => row._mixed);

        this.headerChecked = headerCheckedFromChildren || headerCheckedFromRows;
        this.headerMixed = headerMixedFromChildren || headerMixedFromRows;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    /**
     * Coche/décoche toutes les lignes du tableau et des tableaux imbriqués.
     */
    toggleAllCheckboxes() {
      this.rows.forEach((row) => {
        this.$set(row, "_checked", this.headerChecked);
        this.$set(row, "_mixed", false);

        if (row._children?.length) {
          this.toggleChildrenCheckboxes(row);
        }
      });
    },
    /**
     * Coche/décoche toutes les ligne d'un tableau imbriqué.
     * @param {Object} ligne Ligne comprenant les sous-lignes à cocher.
     */
    toggleChildrenCheckboxes(ligne) {
      ligne._children.forEach((child) => {
        this.$set(child, "_checked", ligne._checked);
      });
    },
    /**
     * Effectue une action au clic sur une ligne.
     * Dans le cas d'un lien, on gère le fait que la ligne puisse être un produit et qu'il faut se baser sur son id
     * selon son type d'offre.
     * @param {Number} ligne Infos de la ligne.
     */
    handleRowClick(ligne) {
      if (ligne._routeName) {
        let customLine = ligne;
        if (ligne.offre_defaut) {
          customLine = ligne.offre_defaut;
        } else if (ligne.offre) {
          customLine = ligne.offre;
        }

        let customOffer = null;
        if (ligne.offre_defaut || ligne.offre) {
          if (ligne?.offre?.ean) {
            customOffer = customLine?.offre;
          } else {
            customOffer = customLine?.manuel_numerique
            || customLine?.fourniture
            || customLine?.article;
          }
        }

        this.$router.push({
          name: ligne._routeName,
          params: { id: customOffer?.id || customLine.id },
        });
      } else {
        this.$set(ligne, "_expanded", !ligne._expanded);
      }
    },
    /**
     * Tri des éléments selon la clé.
     * @param {Object} sortCol Colonne sur laquelle trier.
     */
    sort(sortCol) {
      // Si on trie plusieurs fois sur la même clé à la suite, on inverse l'ordre du tri.
      // Sinon on trie de façon croissante dès qu'on change de colonne.
      const key = sortCol.sortKey || sortCol.key;

      if (!this.internalSort) {
        // Si le tri se fait via l'API, on emit la clé et le sens du tri pour refaire un fetch.
        const activeSort = { ...this.activeSort };

        if (activeSort.key !== key) {
          activeSort.sortAscended = true;
        } else {
          activeSort.sortAscended = !activeSort.sortAscended;
        }

        activeSort.key = key;
        this.$emit("sort", activeSort);
      } else {
        // Si le tri se fait en front, on emit les lignes triées.
        let sortedLines = this.rows;

        if (this.internalActiveSort.key !== key) {
          sortedLines = sortedLines.sort((a, b) => {
            if (a && b) {
              // On gère le cas d'un objet en profondeur
              const nestedKeys = key.split(".");
              const aKey = getNestedProperty(a, nestedKeys);
              const bKey = getNestedProperty(b, nestedKeys);

              if (Number.isNaN(parseFloat(aKey)) || Number.isNaN(parseFloat(bKey))) {
                if (aKey < bKey) return -1;
                if (aKey > bKey) return 1;
              } else {
                if (parseFloat(aKey) < parseFloat(bKey)) return -1;
                if (parseFloat(aKey) > parseFloat(bKey)) return 1;
              }
            }
            return 0;
          });
          this.internalActiveSort.sortAscended = true;
        } else {
          // Si le dernier tri est sur la même colonne, on effectue l'inverse du précédent tri
          sortedLines = sortedLines.reverse();
          this.internalActiveSort.sortAscended = !this.internalActiveSort.sortAscended;
        }

        this.internalActiveSort.key = key;
        this.$emit("sort", sortedLines);
      }
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/table/table_classic.scss";
</style>
