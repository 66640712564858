var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-classic-new"},[_c('table',[_c('thead',[_c('tr',[(_vm.checkboxes)?_c('th',{staticClass:"checkbox"},[_c('div',[_c('ButtonCheckbox',{attrs:{"name":"header-checkbox","mix":_vm.headerMixed},on:{"change":function($event){return _vm.toggleAllCheckboxes()}},model:{value:(_vm.headerChecked),callback:function ($$v) {_vm.headerChecked=$$v},expression:"headerChecked"}})],1)]):_vm._e(),_vm._l((_vm.columns),function(col){return _c('th',{key:col.key,class:col.key},[_c(col.notSortable ? 'div' : 'button',{tag:"component",staticClass:"button-filter",on:{"click":function($event){!col.notSortable && _vm.sort(col)}}},[(!col.notSortable)?_c('UisSorting',{attrs:{"size":"16"}}):_vm._e(),_vm._v(" "+_vm._s(col.name)+" "),(col.icon)?_c(col.icon,{tag:"component",attrs:{"size":"16"}}):_vm._e()],1)],1)})],2),_c('tr',{staticClass:"separator"})]),_c('tbody',[_vm._l((_vm.rows),function(row,index){return [_c('tr',{key:index,class:[
            row._variant,
            `ligne-${row.id}`,
            {
              highlighted: row._checked,
              disabled: !row._routeName,
              expanded: row._expanded,
            },
          ],on:{"click":function($event){!row._disabled ? _vm.handleRowClick(row) : null}}},[(_vm.checkboxes)?_c('td',{staticClass:"checkbox"},[_c('div',[_c('ButtonCheckbox',{staticClass:"visibility-hidden",attrs:{"name":"header-checkbox","mix":row._mixed},on:{"change":function($event){row._children?.length ? _vm.toggleChildrenCheckboxes(row) : null}},nativeOn:{"click":function($event){$event.stopPropagation();}},model:{value:(row._checked),callback:function ($$v) {_vm.$set(row, "_checked", $$v)},expression:"row._checked"}})],1)]):_vm._e(),_vm._l((_vm.columns),function(col){return _c('td',{key:col.key,class:col.key},[_vm._t(col.key,function(){return [_vm._v(" "+_vm._s(row[col.key])+" ")]},{"value":row})],2)})],2),(row._additional)?_c('tr',{key:`additional_${index}`,staticClass:"additional-line"},[_c('td',{attrs:{"colspan":_vm.checkboxes ? _vm.columns.length + 1 : _vm.columns.length}},[_vm._t("additional-line",null,{"value":row})],2)]):_vm._e(),(row._children?.length && row._expanded)?_c('tr',{key:`child_${index}`,staticClass:"child-row"},[_c('td',{attrs:{"colspan":_vm.checkboxes ? _vm.columns.length + 1 : _vm.columns.length}},[_vm._t("child-row",null,{"value":row})],2)]):_vm._e(),_c('tr',{key:`space_${index}`,staticClass:"separator"})]})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }