<template>
  <span
    class="badge"
    :class="[variant, { medium, }, ]"
    v-html="title || label"
  />
</template>

<script>
/**
 * Affiche le badge souhaité.
 */
export default {
  name: "Badge",
  props: {
    /**
     * Variante du badge.
     */
    variant: {
      type: String,
      validator: (value) => ["new", "promo", "remise", "info", "warning", "error", "remplace"].includes(value),
      required: true,
    },
    /**
     * Texte à afficher.
     */
    title: {
      type: String,
      default: null,
    },
    /**
     * Taille du texte.
     */
    medium: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    label() {
      switch (this.variant) {
        case "new":
          return this.$t("general.nouveau");
        case "promo":
          return this.$t("general.promo");
        case "remplace":
          return this.$t("produit.remplace");
        default:
          return this.title;
      }
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/badge.scss";
</style>
