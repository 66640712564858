<template>
  <section
    id="table_total"
    class="grid-zone"
  >
    <p
      v-if="messageNumerique"
      class="text-small italic no-refundable"
    >
      <UisExclamationTriangle size="16" />
      {{ $t('licence.licences-numeriques-ni-echangeables-ni-remboursables') }}
    </p>
    <div
      v-if="objectInfos.total_ttc !== null || objectInfos.total_ht !== null"
      id="total"
    >
      <span class="s5 bold">
        {{ provisoire ? $t('general.total-en-cours-verification') : $t('general.total-estime') }}
      </span>
      <template v-if="!provisoire">
        <div v-if="objectInfos.total_ttc !== objectInfos.total_ht">
          <strong
            v-if="objectInfos.total_ttc !== objectInfos.total_ht"
            class="s3 all-taxes"
            v-html="$t('prix.ttc', { prix: moneyFilter(objectInfos.total_ttc), })"
          />
          <span
            class="s5 light duty-free"
            v-html="$t('prix.ht', { prix: moneyFilter(objectInfos.total_ht), })"
          />
        </div>
        <div v-else>
          <span
            class="s3 light duty-free"
            v-html="$t('prix.ht', { prix: moneyFilter(objectInfos.total_ht), })"
          />
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { moneyFilter } from "@lde/core_lde_vue";

import { UisExclamationTriangle } from "@iconscout/vue-unicons-solid";

/**
 * Affiche le prix total des objectInfoss de l'utilisateur.
 */
export default {
  name: "TableTotal",
  components: {
    UisExclamationTriangle,
  },
  props: {
    /**
     * Informations de l'objet (liste, devis ou commande).
     */
    objectInfos: {
      type: Object,
      required: true,
    },
    /**
     * Affiche un message stipulant que le numérique n'est pas échangeabl/remboursable.
     */
    messageNumerique: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    provisoire() {
      return this.objectInfos.provisoire
        && this.objectInfos.total_ttc === 0
        && this.objectInfos.total_ht === 0;
    },
  },
  methods: {
    moneyFilter,
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/table/table_total.scss";
</style>
